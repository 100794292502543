<script setup lang="ts">
import type { NoteConfig } from "@/stores/UserState";
import { computed, toRaw } from "vue";

const props = defineProps<{
  config: NoteConfig;
  activeNoteConfig: NoteConfig | null;
}>();

const displayedLabel = computed<string>(() => {
  return props.config.label || props.config.name;
});

const isActive = computed<boolean>(() => {
  return Object.is(props.config, props.activeNoteConfig);
});
function handleDragStart(e: DragEvent) {
  const data = {
    note: toRaw(props.config),
  };
  e.dataTransfer?.setData("text/plain", JSON.stringify(data));
}
</script>

<template>
  <button
    class="btn design-button flex-grow-0"
    draggable="true"
    @dragstart="handleDragStart"
    :class="{
      'btn-dark': isActive,
      'btn-secondary': !isActive,
    }"
  >
    {{ displayedLabel }}
  </button>
</template>

<style>
.design-button {
  white-space: nowrap;
  pointer-events: all;
}
</style>
@/stores/UserState
