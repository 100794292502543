import { Logger } from "@/logger";
import type { DRViewer } from "@/open-cloud/DRViewer";
import Toolbox from "./ODAToolbox";

export type Model = {
  pseudo: "ACTIVE" | "MUP";
};

export class ModelBuilder {
  viewer: DRViewer;

  constructor(viewer: DRViewer) {
    this.viewer = viewer;
  }

  get visLib(): typeof VisualizeJS {
    return this.viewer.visLib();
  }

  get visViewer(): VisualizeJS.Viewer {
    return this.viewer.visViewer();
  }

  // Allows to clone entities to a Model
  // There is no way to move an entity to another model. Another entity must be created
  // in the targeted model and the property of the first entity copied to the new one.
  // There are several types of entities. They have different
  // properties and are then treated separately.

  // ulysse: what about  https://cloud.opendesign.com/docs/index.html#/vis/OdTvEntity?id=copytotargetentityid ?

  static cloneEntity(
    entId: VisualizeJS.OdTvEntityId,
    model: VisualizeJS.TvModel
  ): VisualizeJS.OdTvEntityId {
    if (entId.getType() === 1) {
      const entPtr = entId.openObject();
      const newName = entPtr.getName();
      const entIdTemp = model.appendEntity(newName);
      entPtr.copyTo(entIdTemp);
      entPtr.delete();
      return entIdTemp;
    } else if (entId.getType() === 2) {
      const insertPtr = entId.openObjectAsInsert();
      const entIdTemp = model.appendInsert(
        insertPtr.getBlock(),
        insertPtr.getName()
      );
      const insertPtrTemp = entIdTemp.openObjectAsInsert();
      insertPtrTemp.setPosition(insertPtr.getPosition());
      const itr = insertPtr.getSubEntitiesIterator();
      for (; !itr.done(); itr.step()) {
        const subentId = itr.getEntity();
        const subent = subentId.openObject();
        const cloneId = insertPtrTemp.appendSubEntity(subent.getName());
        subent.copyTo(cloneId);
        Toolbox.deleteAll([subentId, subent, cloneId]);
      }
      itr.delete();

      insertPtrTemp.setRotation(insertPtr.getRotation());
      insertPtrTemp.setNormal(insertPtr.getNormal());
      insertPtrTemp.setBlockTransform(insertPtr.getBlockTransform());
      insertPtrTemp.setColor(insertPtr.getColor());
      insertPtrTemp.setContentsSelectable(insertPtr.getContentsSelectable());
      //  does not work... but it is not a big deal
      //insertPtrTemp.setVisibility(insertPtr.getVisibility());
      insertPtrTemp.setExtents(insertPtr.getExtents().ext);
      insertPtrTemp.setScaleFactors(insertPtr.getScaleFactors());
      insertPtrTemp.setLayer(insertPtr.getLayer());
      insertPtr.delete();
      insertPtrTemp.delete();
      return entIdTemp;
    } else {
      Logger.error(`ModelBuilder.cloneEntity : entity type is unknown`);
      throw new TypeError("TypeError : entity type is not 1 or 2");
    }
  }

  static cloneEntities(
    entIds: VisualizeJS.OdTvEntityId[],
    model: VisualizeJS.TvModel
  ): VisualizeJS.OdTvEntityId[] {
    const cloneIds: VisualizeJS.OdTvEntityId[] = [];
    for (const entId of entIds) {
      const cloneId = ModelBuilder.cloneEntity(entId, model);
      cloneId ? cloneIds.push(cloneId) : null;
    }
    return cloneIds;
  }

  findModel(modelRef: Model["pseudo"]): VisualizeJS.TvModel {
    if (modelRef === "ACTIVE") return this.visViewer.getActiveModel();
    else return this.visViewer.getMarkupModel();
  }

  getEntityByHandle(handle: string): VisualizeJS.OdTvEntityId | null {
    const model = this.findModel("ACTIVE");
    const entId = model.findEntity(handle);
    if (!entId || entId?.isNull()) {
      entId.delete();
      return null;
    }
    return entId;
  }

  getModel(pseudo: Model["pseudo"]): VisualizeJS.TvModel {
    const viewer = this.visViewer;
    let model: VisualizeJS.TvModel;
    switch (pseudo) {
      case "ACTIVE":
        model = viewer.getActiveModel();
        break;
      case "MUP":
        model = viewer.getMarkupModel();
        break;
      default:
        throw new Error(`can't find model for pseudo ${pseudo}`);
    }
    return model;
  }
  // create an entity with the name argument and in the model (pseudo of the model)
  createEntity(
    name: string,
    pseudo: Model["pseudo"]
  ): VisualizeJS.OdTvEntityId {
    const model = this.getModel(pseudo);
    const entId = model.appendEntity(name);
    model.delete();
    return entId;
  }

  logModels() {
    const itr = this.visViewer.getModelIterator();
    for (; !itr.done(); itr.step()) {
      const model: VisualizeJS.TvModel = itr.getModel();
      Logger.debug(model.getName());
      model.delete();
    }
    itr.delete();
  }
}
