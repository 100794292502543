<script setup lang="ts">
import { onBeforeMount, onBeforeUnmount, onMounted, ref, toRaw } from "vue";
import TextInputMenu from "@/components/canvas/Tools/TextInputMenu.vue";
import FloatingMenu from "@/components/canvas/FloatingMenu/FloatingMenu.vue";
import Toaster from "@/components/canvas/Toaster.vue";
import SaveBadge from "@/components/canvas/SaveBadge.vue";
import TopMenu from "@/components/canvas/TopMenu.vue";
import DraggerMenu from "@/components/canvas/Tools/DraggerMenu.vue";
import Loader from "@/components/Loader.vue";
import { drawingRepository } from "@/repositories/drawing.repository";
import { Facade } from "@/open-cloud/Facade";
import Settings from "@/stores/Settings";
import { disposeBootstrap } from "../utils/bootstrap.utils";
import CanvasSettingsState from "@/stores/CanvasSettingsState";
import BottomMenu from "@/components/canvas/Settings/BottomMenu.vue";
import { Logger } from "@/logger";
import UserState, { type NoteConfig } from "@/stores/UserState";
import { keyHandler } from "@/components/canvas/KeyHandler";
import KeyboardModal from "@/components/providers/KeyboardModal.vue";
import NoteMenuState from "@/stores/NoteMenuState";

const props = defineProps<{
  drawingId: number;
}>();

CanvasSettingsState.initialize(props.drawingId, UserState.getLegends());

let isLoading = ref(false);
const canvas = ref<HTMLCanvasElement>();
Facade.createViewer(toRaw(Settings.onlyStylusOn));

async function initializeViewer() {
  let start = performance.now();
  Logger.info(`DrawingCanvas.vue : start init viewer`);
  if (!canvas.value) return;

  const onProgress = (event: ProgressEvent) => {
    if (event.total) {
      const progress = event.loaded / event.total;
    }
  };

  await Facade.initializeViewer(toRaw(canvas.value), onProgress);
  let end = performance.now();
  Logger.info(`DrawingCanvas.vue : done init viewer in ${end - start} ms`);
  if (!props.drawingId) {
    return;
  } else {
    if (!Number.isInteger(props.drawingId)) {
      Logger.error(`Cannot open drawing with id ${props.drawingId}`);
    }
    const drawingId = Number.parseInt(`${props.drawingId}`, 10);
    start = performance.now();
    Logger.info(`DrawingCanvas.vue : get last draft`);
    const drawing = await drawingRepository.getLastSaveOrDraft(drawingId);
    end = performance.now();
    Logger.info(
      `DrawingCanvas.vue : done getting last draft ${end - start} ms`
    );
    if (drawing) {
      start = performance.now();
      Logger.info(`DrawingCanvas.vue : open drawing`);
      await Facade.openVsf(drawingId, drawing.binary, drawing.format);
      end = performance.now();
      Logger.info(`DrawingCanvas.vue : done opening ${end - start} ms`);
    } else {
      Logger.error(`DrawingCanvas.vue : Cannot open empty vsf`);
    }
  }

  Facade.setViewerTheme(toRaw(Settings.actviveTheme));

  //perform a regenAll to take into account new embeded files and background
  start = performance.now();
  Logger.info(`DrawingCanvas.vue : start regenerating drawing`);
  Facade.regenAll();
  end = performance.now();
  Logger.info(`DrawingCanvas.vue : done regenerating ${end - start} ms`);
}

onMounted(async () => {
  isLoading.value = true;
  await initializeViewer();
  CanvasSettingsState.onDrawingOpen();
  keyHandler.init();
  isLoading.value = false;
});

onBeforeMount(() => {
  // disable overscroll when canvas is shown
  document.body.classList.add("body-no-overscroll");
});

onBeforeUnmount(async () => {
  // re-enable scrolling
  document.body.classList.remove("body-no-overscroll");
  disposeBootstrap();
  try {
    if (props.drawingId) await Facade.saveDraft();
  } finally {
    Facade.dispose();
  }
  keyHandler.dispose();
});

function handleDrop(e: DragEvent) {
  if (!e?.dataTransfer) return;
  const data = JSON.parse(e.dataTransfer.getData("text/plain"));
  if (data.note) {
    let config = data.note as NoteConfig;
    config = NoteMenuState.computeNoteConfig(config);
    const position: [number, number] = [
      e.offsetX * window.devicePixelRatio,
      e.offsetY * window.devicePixelRatio,
    ];
    const message: string = config.label || config.name;
    Facade.addText(message, position, config, true, true);
  }
}
</script>

<template>
  <KeyboardModal>
    <div class="h-100 w-100">
      <div class="h-100 w-100">
        <!-- It is important that this canvas stays in it's own container. The reason is that the Konvas rendered will append another canvas in the parent container.
        So if the toolbar are in the same container, this new canvas will be inserted in the dom after the toolbars and therefore capture pointer events (click on tool button won't work)
       -->
        <canvas
          ref="canvas"
          id="dr-canvas"
          @dragover.prevent
          @drop.prevent="handleDrop"
        />
        <FloatingMenu />
      </div>
      <TopMenu />
      <div
        class="position-absolute top-50 translate-middle-y ms-1 d-flex align-items-start flex-column"
      >
        <DraggerMenu />
      </div>
      <div
        class="position-absolute d-inline-flex start-0 bottom-0 m-3 align-items-end"
      >
        <BottomMenu :drawingId="drawingId" />
        <TextInputMenu />
        <form class="ms-1">
          <SaveBadge :drawingId="props.drawingId" />
        </form>
      </div>
    </div>
  </KeyboardModal>
  <Loader :active="isLoading" />
  <Toaster />
</template>

<style>
.body-no-overscroll {
  overscroll-behavior: none;
  overflow: hidden;
  position: fixed;
}

#canvas {
  width: 100%;
}
</style>
