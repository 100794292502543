import type { DRViewer } from "@/open-cloud/DRViewer";

export interface TextData {
  refpoint: VisualizeJS.Point3;
  alignmentmode?: number;
  message: string;
}

export class TextBuilder {
  viewer: DRViewer;

  constructor(viewer: DRViewer) {
    this.viewer = viewer;
  }

  get visLib(): any {
    return this.viewer.visLib();
  }

  static isTextGeometry(geometry: VisualizeJS.OdTvGeometryDataId) {
    return geometry.getType() === 8;
  }

  get visViewer(): any {
    return this.viewer.visViewer();
  }

  /**
   * Add a text to an entity
   * For alignment, refer to https://docs.opendesign.com/tv/OdTvTextStyle__AlignmentType.html
   * alignment does not work well, if textsize is not set,
   * the vertical algnment does not work
   * @param entityId
   * @param point
   * @param text
   * @param alignment
   */
  static addText(entityId: VisualizeJS.OdTvEntityId, data: TextData) {
    const entity = entityId.openObject();
    const geomId = entity.appendText(data.refpoint, data.message);
    const text = geomId.openAsText();
    text.setPosition(data.refpoint);
    if (data.alignmentmode) {
      text.setAlignmentMode(data.alignmentmode);
    }
    text.delete();
    geomId.delete();
    entity.delete();
  }
  // returns the content of the TextId
  static getTextContent(geometry: VisualizeJS.OdTvGeometryDataId): string {
    const text = geometry.openAsText();
    const content = text.getString();
    text.delete();
    return content;
  }

  static getTextContents(
    geometries: VisualizeJS.OdTvGeometryDataId[]
  ): string[] {
    const contents: string[] = [];
    for (const geometry of geometries) {
      const text = geometry.openAsText();
      contents.push(text.getString());
      text.delete();
    }
    return contents;
  }

  static edit(geometry: VisualizeJS.OdTvGeometryDataId, newContent: string) {
    const text = geometry.openAsText();
    text.setString(newContent);
    text.delete();
  }
}
